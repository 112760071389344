import styled, { DefaultTheme } from "styled-components";
import { space, variant, typography } from "styled-system";
import { lightColors, darkColors } from "@pancakeswap/ui/tokens/colors";
import { Colors } from "../../theme/types";
import { scaleVariants, styleVariants } from "./theme";
import { TagProps, variants } from "./types";

interface ThemedProps extends TagProps {
  theme: DefaultTheme;
}

const getOutlineStyles = ({ outline, theme, variant: variantKey = variants.PRIMARY }: ThemedProps) => {
  if (outline) {
    const themeColorKey = styleVariants[variantKey].backgroundColor as keyof Colors;
    const color = theme.colors[themeColorKey];

    return `
      color: ${color};
      background: ${theme.colors.background};
      border: 2px solid ${color};
    `;
  }

  return "";
};

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getColorStyles = ({ color, theme }: ThemedProps) => {
  if (color) {
    const themeColorKey = styleVariants[color].backgroundColor as keyof Colors;
    const colors = theme.isDark ? darkColors : lightColors;
    const colorValue = colors[themeColorKey] ?? colors.primary;
    const rgbaValue = hexToRgba(colorValue, 0.2);
    return `
      color: var(--colors-${themeColorKey});
      background: ${rgbaValue};
    `;
  }
  return "";
};

export const StyledTag = styled.div<ThemedProps>`
  align-items: center;
  border-radius: 16px;
  color: #ffffff;
  display: inline-flex;
  font-weight: 400;
  white-space: nowrap;

  & > svg {
    fill: currentColor;
  }

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}

  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })}
  ${variant({
    variants: styleVariants,
  })}
  ${space}
  ${typography}

  ${getColorStyles}
  ${getOutlineStyles}
`;

export default null;
