import { useEffect } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApiGetSigned, ApiSign } from 'services/global'
import { Modal, useModal, Button } from '@pancakeswap/uikit'
import { signMessage } from '@wagmi/core'

const privacyPolicyUrl = {
  website: {
    url: 'https://potatoswap.finance/',
    text: 'Website',
  },
  termsofService: {
    url: 'https://potatoswap.finance/terms-of-service',
    text: 'Terms of Services',
  },
  privacyPolicy: {
    url: 'https://potatoswap.finance/privacy-policy',
    text: 'Privacy Policy',
  },
}

const getSigMessage = async (message) => {
  try {
    const signature = await signMessage({
      message,
    })
    return signature
  } catch (error) {
    // 异常处理代码
    return false
  }
}

const SignModal = ({ onDismiss }) => {
  const { account } = useActiveWeb3React()
  const signfun = async () => {
    const message = `By accessing or using Interface (as defined in the Terms of Use) at https://potatoswap.finance/terms-of-service, I agree to be bound by the Terms of Use (https://potatoswap.finance/terms-of-service), the risks involved and confirm further that I have read and fully understood the Privacy Policy (https://potatoswap.finance/privacy-policy) .\n
    I hereby further represent and warrant that:\n
    I’m not a national of the United States of America, nor a resident of or located in the United States of America (including its territories: American Samoa, Guam, Puerto Rico, the Northern Mariana Islands and the U.S. Virgin Islands）, People’s Republic of China (excluding the Hong Kong Special Administrative Region, Macau Special Administrative Region, and Taiwan), or any other Restricted Territory (as defined in the Terms of Service);\n
    I’m not a Sanctions List Person (as defined in the Terms of Use) nor acting on behalf of a Sanctions List Person;\n
    I acknowledge that my use of the PotatoSwap Interface has risks, including the disruption, suspension, inaccessibility of the functions on the Interface, that the PotatoSwap Interface and related platform, applications and software are experimental, and the use of experimental software may result in complete loss of my assets and funds.`
    const signature = await getSigMessage(message)
    if (signature) {
      ApiSign(account, message, signature)
        .then((res) => {
          if (res.code === 0 && res.msg === 'OK' && res.data.success) {
            onDismiss?.()
          }
        })
        .catch(() => {
          onDismiss?.()
          return false
        })
    } else {
      return false
    }
    return true
  }
  return (
    <>
      <Modal hideCloseButton bodyPadding="0" onDismiss={onDismiss} style={{ width: 'auto' }} title="">
        <div className="rounded-xl gtPhone:w-[480px] phone:w-auto overflow-hidden">
          <div className="text-[20px] font-medium leading-5 text-[#151027] mb-[12px] text-center border-b-[1px] border-[#EBEBEB] pb-[12px]">
            SIGNATURE REQUEST
          </div>

          <div className="px-[16px] overflow-y-scroll max-h-[420px]">
            <div className="text-[16px] font-medium leading-5 text-[#151027] mb-[12px]">
              Only sign this message if you fully understand the content and trust the requesting site.
            </div>
            <div className="text-[16px] leading-5 text-[#151027] mb-[12px]">You are signing:</div>
            <div className="text-[16px] font-medium leading-5 text-[#151027] mb-[12px]">Message:</div>
            <div className="text-[16px] leading-5 text-[#151027] mb-[12px]">
              By accessing or using Interface (as defined in the Terms of Service) at{` `}
              <a href={privacyPolicyUrl.website.url} className="text-[#7ABBFF]" target="_black">
                {privacyPolicyUrl.website.text}
              </a>
              , I agree to be bound by the{` `}
              <a href={privacyPolicyUrl.termsofService.url} className="text-[#7ABBFF]" target="_black">
                {privacyPolicyUrl.termsofService.text}
              </a>
              , the risks involved and confirm further that I have read and fully understood the{` `}
              <a href={privacyPolicyUrl.privacyPolicy.url} className="text-[#7ABBFF]" target="_black">
                {privacyPolicyUrl.privacyPolicy.text}
              </a>
              .
            </div>

            <div>
              <div className="text-[16px] font-medium leading-5 text-[#151027] mb-[12px]">
                I hereby further represent and warrant that:
              </div>
              <ul className="pl-[16px]">
                <li className="text-[16px] leading-5 opacity-50 text-[#151027] mb-[12px] list-disc">
                  I’m not a national of the United States of America, nor a resident of or located in the United States
                  of America (including its territories: American Samoa, Guam, Puerto Rico, the Northern Mariana Islands
                  and the U.S. Virgin Islands）, People’s Republic of China (excluding the Hong Kong Special
                  Administrative Region, Macau Special Administrative Region, and Taiwan), or any other Restricted
                  Territory (as defined in the Terms of Service);
                </li>
                <li className="text-[16px] leading-5 opacity-50 text-[#151027] mb-[12px] list-disc">
                  I’m not a Sanctions List Person (as defined in the Terms of Use) nor acting on behalf of a Sanctions
                  List Person;
                </li>
                <li className="text-[16px] leading-5 opacity-50 text-[#151027] mb-[12px] list-disc">
                  I acknowledge that my use of the PotatoSwap Interface has risks, including the disruption, suspension,
                  inaccessibility of the functions on the Interface, that the PotatoSwap Interface and related platform,
                  applications and software are experimental, and the use of experimental software may result in
                  complete loss of my assets and funds.
                </li>
              </ul>
            </div>
          </div>

          <div className="flex items-center justify-between pt-[16px] p-[16px] gap-4 border-t-[1px] border-[#EBEBEB]">
            <Button
              className="w-full"
              onClick={() => {
                onDismiss?.()
              }}
            >
              Reject
            </Button>
            <Button className="w-full" onClick={signfun}>
              Sign
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export function SignatureModal() {
  const { account, chainId } = useActiveWeb3React()
  const [onSignModalModal] = useModal(<SignModal onDismiss={undefined} />, false, false, 'SignModal')
  useEffect(() => {
    if (account)
      ApiGetSigned(account)
        .then((res) => {
          if (res.code === 0 && res.msg === 'OK' && !res.data.signed) {
            onSignModalModal()
          }
        })
        .catch(() => {})
  }, [account, chainId, onSignModalModal])

  return <></>
}
