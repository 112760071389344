import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | PotatoSwap',
  defaultTitle: 'PotatoSwap',
  description:
    'PotatoSwap is a DEX + Launchpad native to the X Layer. PotatoSwap aims to allow access to deep liquidity to supercharge financial activities, as well as to bring communities together to help launch new projects that can bring the X Layer to greater heights.',
  // twitter: {
  //   cardType: 'summary_large_image',
  //   handle: '@PancakeSwap',
  //   site: '@PancakeSwap',
  // },
  // openGraph: {
  //   title: '🥞 PancakeSwap - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
  //   description:
  //     'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  //   images: [{ url: 'https://assets.pancakeswap.finance/web/og/hero.jpg' }],
  // },
}
