import { HttpRequestAxios } from './HttpRequestAxios'

/**
 * Signed
 * @param
 */
export const ApiGetSigned = (address: string) => {
  return HttpRequestAxios.get(`/v1/agreement/signed?addr=${address}`).then((res) => res?.data)
}

/**
 * sign
 * @param params
 */
export const ApiSign = (addr: string, message: string, sign: string) => {
  return HttpRequestAxios.post(`/v1/agreement/sign`, {
    addr,
    message,
    sign,
  }).then((res) => res?.data)
}
