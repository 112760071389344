import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  body::-webkit-scrollbar {
    display: none;
  }
  
  body::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`

export default GlobalStyle
